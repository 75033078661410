<template>
  <div>
    <requested-link></requested-link>
  </div>
</template>

<script>
import RequestedLink from "../../components/RequestedLink";
export default {
  components: {
    RequestedLink,
  },
  name: "PendingRequest",
};
</script>

<style scoped></style>
